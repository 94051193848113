import { default as _91_46_46_46all_93efrImQ7GwFMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/[...all].vue?macro=true";
import { default as categorySMd7UM2horMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/admin/document/category.vue?macro=true";
import { default as editorWSmjZ8Tv0mMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/admin/document/editor.vue?macro=true";
import { default as indexeZghY0gTsuMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/admin/document/index.vue?macro=true";
import { default as navm8pNs7oixvMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/admin/document/nav.vue?macro=true";
import { default as hometaCA1RZHlKMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/admin/home.vue?macro=true";
import { default as indexL10U1K6TpzMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/admin/index.vue?macro=true";
import { default as bty7UjStlMOUMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/bt.vue?macro=true";
import { default as CaseList0qoyoSr5FMMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/case/CaseList.vue?macro=true";
import { default as detail1gjkZq7pFWCMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/case/detail1.vue?macro=true";
import { default as detail26em0a0RkMnMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/case/detail2.vue?macro=true";
import { default as detail3WTC0iSiE79Meta } from "/root/.jenkins/workspace/tduck-cms-front/pages/case/detail3.vue?macro=true";
import { default as detail4dF5v4dXqukMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/case/detail4.vue?macro=true";
import { default as detail55kqn0LmyGSMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/case/detail5.vue?macro=true";
import { default as detail6TBSetxDB3TMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/case/detail6.vue?macro=true";
import { default as listW4maej7a90Meta } from "/root/.jenkins/workspace/tduck-cms-front/pages/case/list.vue?macro=true";
import { default as _91key_93xNOzLGoon8Meta } from "/root/.jenkins/workspace/tduck-cms-front/pages/doc/[cateKey]/[key].vue?macro=true";
import { default as xSAOcX8F9tIMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/fun/x.vue?macro=true";
import { default as _91id_93OxY3qU8jOrMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/hi/[id].vue?macro=true";
import { default as indexIPkVxjNXFoMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/index.vue?macro=true";
import { default as loginMCtD6HbMeKMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/login.vue?macro=true";
import { default as news11131SteggtE9sMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/news/news1113.vue?macro=true";
import { default as niumIKNviDsz3gMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/nium.vue?macro=true";
import { default as indexTKtUrabeqRMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/price/index.vue?macro=true";
import { default as prouCNtLx17dYMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/pro.vue?macro=true";
import { default as PriceXziPAQhXKJMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/scheme/Price.vue?macro=true";
import { default as testI0klWVe8zvMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/test.vue?macro=true";
import { default as xKfBeopxafMMeta } from "/root/.jenkins/workspace/tduck-cms-front/pages/x.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93efrImQ7GwFMeta?.name ?? "all",
    path: _91_46_46_46all_93efrImQ7GwFMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93efrImQ7GwFMeta || {},
    alias: _91_46_46_46all_93efrImQ7GwFMeta?.alias || [],
    redirect: _91_46_46_46all_93efrImQ7GwFMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: categorySMd7UM2horMeta?.name ?? "admin-document-category",
    path: categorySMd7UM2horMeta?.path ?? "/admin/document/category",
    meta: categorySMd7UM2horMeta || {},
    alias: categorySMd7UM2horMeta?.alias || [],
    redirect: categorySMd7UM2horMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/admin/document/category.vue").then(m => m.default || m)
  },
  {
    name: editorWSmjZ8Tv0mMeta?.name ?? "admin-document-editor",
    path: editorWSmjZ8Tv0mMeta?.path ?? "/admin/document/editor",
    meta: editorWSmjZ8Tv0mMeta || {},
    alias: editorWSmjZ8Tv0mMeta?.alias || [],
    redirect: editorWSmjZ8Tv0mMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/admin/document/editor.vue").then(m => m.default || m)
  },
  {
    name: indexeZghY0gTsuMeta?.name ?? "admin-document",
    path: indexeZghY0gTsuMeta?.path ?? "/admin/document",
    meta: indexeZghY0gTsuMeta || {},
    alias: indexeZghY0gTsuMeta?.alias || [],
    redirect: indexeZghY0gTsuMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/admin/document/index.vue").then(m => m.default || m)
  },
  {
    name: navm8pNs7oixvMeta?.name ?? "admin-document-nav",
    path: navm8pNs7oixvMeta?.path ?? "/admin/document/nav",
    meta: navm8pNs7oixvMeta || {},
    alias: navm8pNs7oixvMeta?.alias || [],
    redirect: navm8pNs7oixvMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/admin/document/nav.vue").then(m => m.default || m)
  },
  {
    name: hometaCA1RZHlKMeta?.name ?? "admin-home",
    path: hometaCA1RZHlKMeta?.path ?? "/admin/home",
    meta: hometaCA1RZHlKMeta || {},
    alias: hometaCA1RZHlKMeta?.alias || [],
    redirect: hometaCA1RZHlKMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/admin/home.vue").then(m => m.default || m)
  },
  {
    name: indexL10U1K6TpzMeta?.name ?? "admin",
    path: indexL10U1K6TpzMeta?.path ?? "/admin",
    meta: indexL10U1K6TpzMeta || {},
    alias: indexL10U1K6TpzMeta?.alias || [],
    redirect: indexL10U1K6TpzMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: bty7UjStlMOUMeta?.name ?? "bt",
    path: bty7UjStlMOUMeta?.path ?? "/bt",
    meta: bty7UjStlMOUMeta || {},
    alias: bty7UjStlMOUMeta?.alias || [],
    redirect: bty7UjStlMOUMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/bt.vue").then(m => m.default || m)
  },
  {
    name: CaseList0qoyoSr5FMMeta?.name ?? "case-CaseList",
    path: CaseList0qoyoSr5FMMeta?.path ?? "/case/CaseList",
    meta: CaseList0qoyoSr5FMMeta || {},
    alias: CaseList0qoyoSr5FMMeta?.alias || [],
    redirect: CaseList0qoyoSr5FMMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/case/CaseList.vue").then(m => m.default || m)
  },
  {
    name: detail1gjkZq7pFWCMeta?.name ?? "case-detail1",
    path: detail1gjkZq7pFWCMeta?.path ?? "/case/detail1",
    meta: detail1gjkZq7pFWCMeta || {},
    alias: detail1gjkZq7pFWCMeta?.alias || [],
    redirect: detail1gjkZq7pFWCMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/case/detail1.vue").then(m => m.default || m)
  },
  {
    name: detail26em0a0RkMnMeta?.name ?? "case-detail2",
    path: detail26em0a0RkMnMeta?.path ?? "/case/detail2",
    meta: detail26em0a0RkMnMeta || {},
    alias: detail26em0a0RkMnMeta?.alias || [],
    redirect: detail26em0a0RkMnMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/case/detail2.vue").then(m => m.default || m)
  },
  {
    name: detail3WTC0iSiE79Meta?.name ?? "case-detail3",
    path: detail3WTC0iSiE79Meta?.path ?? "/case/detail3",
    meta: detail3WTC0iSiE79Meta || {},
    alias: detail3WTC0iSiE79Meta?.alias || [],
    redirect: detail3WTC0iSiE79Meta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/case/detail3.vue").then(m => m.default || m)
  },
  {
    name: detail4dF5v4dXqukMeta?.name ?? "case-detail4",
    path: detail4dF5v4dXqukMeta?.path ?? "/case/detail4",
    meta: detail4dF5v4dXqukMeta || {},
    alias: detail4dF5v4dXqukMeta?.alias || [],
    redirect: detail4dF5v4dXqukMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/case/detail4.vue").then(m => m.default || m)
  },
  {
    name: detail55kqn0LmyGSMeta?.name ?? "case-detail5",
    path: detail55kqn0LmyGSMeta?.path ?? "/case/detail5",
    meta: detail55kqn0LmyGSMeta || {},
    alias: detail55kqn0LmyGSMeta?.alias || [],
    redirect: detail55kqn0LmyGSMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/case/detail5.vue").then(m => m.default || m)
  },
  {
    name: detail6TBSetxDB3TMeta?.name ?? "case-detail6",
    path: detail6TBSetxDB3TMeta?.path ?? "/case/detail6",
    meta: detail6TBSetxDB3TMeta || {},
    alias: detail6TBSetxDB3TMeta?.alias || [],
    redirect: detail6TBSetxDB3TMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/case/detail6.vue").then(m => m.default || m)
  },
  {
    name: listW4maej7a90Meta?.name ?? "case-list",
    path: listW4maej7a90Meta?.path ?? "/case/list",
    meta: listW4maej7a90Meta || {},
    alias: listW4maej7a90Meta?.alias || [],
    redirect: listW4maej7a90Meta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/case/list.vue").then(m => m.default || m)
  },
  {
    name: _91key_93xNOzLGoon8Meta?.name ?? "doc-cateKey-key",
    path: _91key_93xNOzLGoon8Meta?.path ?? "/doc/:cateKey()/:key()",
    meta: _91key_93xNOzLGoon8Meta || {},
    alias: _91key_93xNOzLGoon8Meta?.alias || [],
    redirect: _91key_93xNOzLGoon8Meta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/doc/[cateKey]/[key].vue").then(m => m.default || m)
  },
  {
    name: xSAOcX8F9tIMeta?.name ?? "fun-x",
    path: xSAOcX8F9tIMeta?.path ?? "/fun/x",
    meta: xSAOcX8F9tIMeta || {},
    alias: xSAOcX8F9tIMeta?.alias || [],
    redirect: xSAOcX8F9tIMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/fun/x.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OxY3qU8jOrMeta?.name ?? "hi-id",
    path: _91id_93OxY3qU8jOrMeta?.path ?? "/hi/:id()",
    meta: _91id_93OxY3qU8jOrMeta || {},
    alias: _91id_93OxY3qU8jOrMeta?.alias || [],
    redirect: _91id_93OxY3qU8jOrMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/hi/[id].vue").then(m => m.default || m)
  },
  {
    name: indexIPkVxjNXFoMeta?.name ?? "index",
    path: indexIPkVxjNXFoMeta?.path ?? "/",
    meta: indexIPkVxjNXFoMeta || {},
    alias: indexIPkVxjNXFoMeta?.alias || [],
    redirect: indexIPkVxjNXFoMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginMCtD6HbMeKMeta?.name ?? "login",
    path: loginMCtD6HbMeKMeta?.path ?? "/login",
    meta: loginMCtD6HbMeKMeta || {},
    alias: loginMCtD6HbMeKMeta?.alias || [],
    redirect: loginMCtD6HbMeKMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/login.vue").then(m => m.default || m)
  },
  {
    name: news11131SteggtE9sMeta?.name ?? "news-news1113",
    path: news11131SteggtE9sMeta?.path ?? "/news/news1113",
    meta: news11131SteggtE9sMeta || {},
    alias: news11131SteggtE9sMeta?.alias || [],
    redirect: news11131SteggtE9sMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/news/news1113.vue").then(m => m.default || m)
  },
  {
    name: niumIKNviDsz3gMeta?.name ?? "nium",
    path: niumIKNviDsz3gMeta?.path ?? "/nium",
    meta: niumIKNviDsz3gMeta || {},
    alias: niumIKNviDsz3gMeta?.alias || [],
    redirect: niumIKNviDsz3gMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/nium.vue").then(m => m.default || m)
  },
  {
    name: indexTKtUrabeqRMeta?.name ?? "price",
    path: indexTKtUrabeqRMeta?.path ?? "/price",
    meta: indexTKtUrabeqRMeta || {},
    alias: indexTKtUrabeqRMeta?.alias || [],
    redirect: indexTKtUrabeqRMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/price/index.vue").then(m => m.default || m)
  },
  {
    name: prouCNtLx17dYMeta?.name ?? "pro",
    path: prouCNtLx17dYMeta?.path ?? "/pro",
    meta: prouCNtLx17dYMeta || {},
    alias: prouCNtLx17dYMeta?.alias || [],
    redirect: prouCNtLx17dYMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/pro.vue").then(m => m.default || m)
  },
  {
    name: PriceXziPAQhXKJMeta?.name ?? "scheme-Price",
    path: PriceXziPAQhXKJMeta?.path ?? "/scheme/Price",
    meta: PriceXziPAQhXKJMeta || {},
    alias: PriceXziPAQhXKJMeta?.alias || [],
    redirect: PriceXziPAQhXKJMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/scheme/Price.vue").then(m => m.default || m)
  },
  {
    name: testI0klWVe8zvMeta?.name ?? "test",
    path: testI0klWVe8zvMeta?.path ?? "/test",
    meta: testI0klWVe8zvMeta || {},
    alias: testI0klWVe8zvMeta?.alias || [],
    redirect: testI0klWVe8zvMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/test.vue").then(m => m.default || m)
  },
  {
    name: xKfBeopxafMMeta?.name ?? "x",
    path: xKfBeopxafMMeta?.path ?? "/x",
    meta: xKfBeopxafMMeta || {},
    alias: xKfBeopxafMMeta?.alias || [],
    redirect: xKfBeopxafMMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/tduck-cms-front/pages/x.vue").then(m => m.default || m)
  }
]