<script setup lang="ts">
import {
  NMessageProvider,
  NDialogProvider
} from 'naive-ui'
import { appName } from '~/constants'
import { onBeforeMount, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

// 配置全局路由滚动行为
router.options.scrollBehavior = (to, from, savedPosition) => {
  return { top: 0 }
}

// 确保初始加载时也在顶部
if (typeof window !== 'undefined') {
  window.history.scrollRestoration = 'manual'
}

useHead({
  title: appName,
  script: [
    {
      src: 'https://hm.baidu.com/hm.js?b4d8a977069083cbc5ee4c3aa72f36ea'
    }
  ]
})

const isMobileMenuOpen = ref(false)

watch(() => isMobileMenuOpen.value, (newValue) => {
  if (newValue) {
    document.body.classList.add('mobile-menu-open')
  } else {
    document.body.classList.remove('mobile-menu-open')
  }
})
</script>

<template>
  <NMessageProvider>
    <NDialogProvider>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </NDialogProvider>
  </NMessageProvider>
</template>

<style>
@import "/assets/styles/index.scss";
html, body, #__nuxt {
  height: 100%;
  margin: 0;
  padding: 0;
}

html.dark {
  background: #222;
  color: white;
}
</style>
